import React from 'react';
import './AvatarComponent.css';

const AvatarComponent = () => {
  return (
    <section className="about" id="nosotros">
    <div className="avatar-content">
      <h2>Services</h2>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          Nullam vestibulum orci a sapien imperdiet maximus.
          Nullam vestibulum orci a sapien imperdiet maximus.
          Lorem ipsum dolor sit amet, consectetur adipiscing elit.q1</p>

    </div>

  </section>
  )
}

export default AvatarComponent;
